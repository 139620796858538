<template>
  <div class="sticky-top fixed-top theme">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <!-- Container wrapper -->
      <div class="container">
        <!-- Navbar brand -->
        <div class="navbar-nav me-auto mb-lg-0">
          <div class="nav-item" @click="home()">
            <a
              v-if="logo == ''"
              class="nav-link pointer"
              href="#"
              @click="home()"
              >{{ this.$store.getters.webname }}</a
            >
            <img
              v-else
              :src="logo"
              @click="home()"
              height="40"
              class="pointer my-1"
            />
          </div>
        </div>

        <ul class="navbar-toggler border-0" style="list-style-type: none">
          <li class="btn-group-vertical">
            <button
              type="button"
              class="button-regis button-landing mx-1"
              @click="navigate('register')"
            >
              <i class="bi bi-person-plus-fill"></i>
              สมัครสมาชิก
            </button>
            <button
              type="button"
              class="button-login button-landing mx-1"
              @click="navigate('login')"
            >
              <i class="bi bi-box-arrow-in-right"></i>
              เข้าสู่ระบบ
            </button>
          </li>
          <!-- <li>
            <button
              type="button"
              class="btn btn-warning btn-sm mb-1"
              data-bs-toggle="modal"
              data-bs-target="#modalLoginID"
            >
              <i class="bi bi-box-arrow-in-right"></i>
              เข้าสู่ระบบ
            </button>
          </li> -->
        </ul>
        <!-- Toggle button -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <i class="bi bi-list"></i>
        </button>

        <!-- Collapsible wrapper -->
        <div class="navbar-collapse collapse" id="navbar">
          <!-- Left links -->
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">{{ this.$store.getters.webname }}</a>
            </li> -->
          </ul>
          <!-- Left links -->
          <div class="d-flex align-items-center">
            <router-link
              to="/"
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
            >
              หน้าบ้าน
            </router-link>

            <!-- <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              disabled
            >
              บทความ
            </button> -->

            <a
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="this.newtab(this.$store.getters.social_line)"
            >
              ติดต่อเรา
            </a>

            <button
              type="button"
              class="btn btn-light mx-1"
              @click="navigate('register')"
            >
              <i class="bi bi-person-plus-fill"></i>
              สมัครสมาชิก
            </button>

            <button
              type="button"
              class="button-login mx-1"
              @click="navigate('login')"
            >
              <i class="bi bi-box-arrow-in-right"></i>
              เข้าสู่ระบบ
            </button>
          </div>
        </div>
        <!-- Collapsible wrapper -->
      </div>
      <!-- Container wrapper -->
    </nav>
    <!-- Navbar -->
  </div>

  <!-- Collapsible offcanvas -->
  <div
    class="offcanvas offcanvas-end bg-dark"
    id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel"
  >
    <!-- Left links -->
    <div class="offcanvas-header">
      <li class="nav-item">
        <a v-if="logo2 == ''" class="nav-link" href="#">{{
          this.$store.getters.webname
        }}</a>
        <img v-else :src="logo2" height="80" class="my-1" />
      </li>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <!-- Left links -->
    <div class="offcanvas-body">
      <div class="d-flex align-items-center">
        <ul data-bs-dismiss="offcanvas">
          <li>
            <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="home()"
            >
              <i class="bi bi-house-fill mx-3" style="font-size: 2rem"></i>
              หน้าบ้าน
            </button>
          </li>
          <!-- <li>
            <button
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              disabled
            >
              <i class="bi bi-book mx-3" style="font-size: 2rem"></i>
              บทความ
            </button>
          </li> -->
          <li>
            <a
              type="button"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="this.newtab(this.$store.getters.social_line)"
            >
              <i
                class="bi bi-telephone-forward-fill mx-3"
                style="font-size: 2rem"
              ></i>
              ติดต่อเรา
            </a>
          </li>
          <li class="mb-3 mt-2">
            <button
              type="button"
              id="register"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="navigate('register')"
            >
              <i
                class="bi bi-person-plus-fill mx-3"
                style="font-size: 2rem"
              ></i>
              สมัครสมาชิก
            </button>
          </li>
          <li class="mb-3 mt-2">
            <button
              type="button"
              id="login"
              class="btn btn-link px-2 text-decoration-none link-light"
              @click="navigate('login')"
            >
              <i
                class="bi bi-box-arrow-in-right mx-3"
                style="font-size: 2rem"
              ></i>
              เข้าสู่ระบบ
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <div class="offcanvas-footer">
        <ul class="text-decoration-none link-light mx-3 my-4">
          <strong>ติดต่อผ่านช่องทางออนไลน์</strong>
          <a
            @click="this.newtab(this.contact.left_url)"
            class="text-light pointer my-2"
            data-bs-dismiss="offcanvas"
          >
            @{{ this.$store.getters.webname }}
          </a>
          <div class="mt-2">
            <span
              v-for="item in contact.right"
              :key="item"
              data-bs-dismiss="offcanvas"
            >
              <img
                fluid
                :src="item.img"
                width="50"
                class="pointer mx-1"
                @click="this.newtab(item.url)"
              />
            </span>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AppHeader',
  data() {
    return {
      ipinfo: {},
      logo: '',
      logo2: '',
      page: '',
      contact: [],
    }
  },
  setup() {
    return {}
  },
  components: {},
  async mounted() {
    this.getvanip()
    await this.callwhitelist()
    await this.setlayout_navbar()
  },
  methods: {
    home() {
      this.$router.push('/')
    },
    navigate(page) {
      this.$router.push('/' + page)
    },
    newtab(URL) {
      window.open(URL, '_blank')
    },
    async getvanip() {
      await axios.get('https://ipinfo.io/json').then((response) => {
        this.ipinfo = response.data
        console.log('ipinfo')
      })
    },
    async callwhitelist() {
      this.$store.commit('setagentid', sessionStorage.getItem('aid'))
    },
    async setlayout_navbar() {
      this.$store.commit('setapiname', 12002)
      this.$store.commit('setAPI')
      await axios
        .post(this.$store.getters.API, {
          agent_id: this.$store.getters.agent_id,
        })
        .then((resp) => {
          console.log('setlayout_navbar')
          if (resp.data.status == 200) {
            if (resp.data.result.template_no == '1') {
              // this.color = 'theme_purple'
              this.color = 'theme_' + resp.data.result.color
              const theme = document.querySelectorAll('.theme')
              theme.forEach((item) => {
                item.classList.remove('theme')
                item.classList.add(this.color)
              })
              this.logo = resp.data.result.logo
              let list = resp.data.result.layout
              for (let i = 0; i < list.length; i++) {
                if (list[i].type == 'header') {
                  this.logo2 = list[i].data.logo
                } else if (list[i].type == 'contact') {
                  this.contact = list[i].data
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log('setlayout_navbar', error)
        })
    },
  },
}
</script>

<style scoped>
@import '@/styles/theme_color.css';
</style>
